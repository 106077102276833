<template>
  <div>
    <date-picker
      v-bind="$attrs"
      :value="value"
      :masks="masks"
      readonly
      v-on="{ ...$listeners, input: e => $emit('input', e.target.value) }"
    >
      <template v-slot="{ inputValue, togglePopover }">
        <el-input :value="inputValue" input-class="rounded" readonly @click="readonly ? false : togglePopover({ placement: 'auto-start' })" />
      </template>
    </date-picker>
  </div>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
const ElInput = () => import('@/components/shared/ELInput')

export default {
  name: 'ElDatePicker',

  components: { ElInput, DatePicker },

  props: {
    value: { type: Date, default: () => new Date() },
    readonly: { type: Boolean, default: false }
  },

  data () {
    return {
      masks: {
        input: 'DD-MM-YYYY'
      }
    }
  }
}
</script>
